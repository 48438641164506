import { render, staticRenderFns } from "./patientListPopup.vue?vue&type=template&id=0a36da36"
import script from "./patientListPopup.vue?vue&type=script&lang=js"
export * from "./patientListPopup.vue?vue&type=script&lang=js"
import style0 from "./patientListPopup.vue?vue&type=style&index=0&id=0a36da36&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports